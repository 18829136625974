<template>
	<div class="main-page">
		<div class="main-menu" :style="{ width: isCollapse ? '64px!important' : '250px' }">
			<div class="mainMenuHeader">
				<img class="logo" src="../assets/img/logo.png" alt="">
				<span class="mainMenuHeader-tips" v-if="isCollapse == false">团火ERP</span>
			</div>
			<el-scrollbar style="height:90%">
				<el-menu unique-opened background-color="#fff" text-color="#3C3C3C" active-text-color="#ff720e"
					:default-active="activeIndex" router :collapse="isCollapse">
					<navMenu-left :navMenus="menuData"></navMenu-left>
				</el-menu>
			</el-scrollbar>
		</div>
		<div class="main-right" :style="{ 'margin-left': isCollapse ? '64px!important' : '250px' }">
			<navMenu-top @menuChildren="getMenuChildren" @event-collapse="isCollapse = $event"></navMenu-top>
			<div class="main-content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import NavMenuLeft from "../components/nav/left.vue";
	import NavMenuTop from "../components/nav/top.vue";
	export default {
		components: {
			'navMenu-left': NavMenuLeft,
			'navMenu-top': NavMenuTop,
		},
		data() {
			return {
				activeIndex: "dataAnalysis",
				isCollapse: false,
				menuData: []
			};
		},
		mounted() {
			this.init()
			this.activeIndex = sessionStorage.getItem('activeMenu')
		},
		methods: {
			getMenuChildren(data) {
				this.menuData = data
			},
			init() {
				this.$http.post(`erp/v1/home/menus`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.menuData = data
						this.menuData.unshift({
							id: 0,
							name: "数据罗盘",
							icon: "el-icon-data-line",
							router: 'dataAnalysis'
						})
						/* 						this.menuData.push({
													id: 100001,
													name: "操作日志",
													icon: "el-icon-data-line",
													router: 'operateLogLst'
												}) */
					}

				})

			}
		}
	};
</script>

<style lang="scss" scoped>
	.main-page {
		width: 100%;
		height: 100%;

		.main-menu {
			transition: width 0.28s;
			background-color: #fff;
			height: 100%;
			position: fixed;
			font-size: 0px;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1001;
			overflow: hidden;

			.mainMenuHeader {
				position: relative;
				height: 60px;
				background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-right: 1px solid #F5F5F5;
				border-bottom: 1px solid #F5F5F5;

				.logo {
					width: 30px;
					height: 30px;
					vertical-align: middle;
					margin-right: 6px;
				}

				.mainMenuHeader-tips {
					font-size: 24px;
					font-weight: bold;
					color: #2C2C2C;
				}
			}

			.el-menu {
				border-right: none;
			}

			/*隐藏文字*/
			/deep/.el-menu--collapse .el-submenu__title span {
				display: none;
			}

			/*隐藏 > */
			/deep/.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
				display: none;
			}
		}

		.main-right {
			min-height: 100%;
			transition: margin-left 0.28s;
			position: relative;
			background: #F0F1F4;

			.main-content {
				padding: 15px;
			}
		}
	}

	/deep/.el-scrollbar__wrap {
		overflow-x: hidden;
	}
</style>